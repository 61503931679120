import * as React from "react";
import { Intro, Experience, Contact, Footer } from "@components";
import "../scss/main.scss"
import Helmet from "react-helmet"

// markup
const IndexPage = () => {
    return (
        <main>
            <Helmet>
                <title>James Neill · Software Engineer</title>
                <meta name="description" content="James Neill is a software engineer specialising in Python, Golang, AWS and Kubernetes."/>
            </Helmet>
            <Intro />
            {/* <Experience /> */}
            {/* <Contact /> */}
            {/* <Footer /> */}
        </main>
    )
}

export default IndexPage
