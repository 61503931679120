import * as React from 'react';
import styled from 'styled-components';

const StyledNavigation = styled.header`
.styled-navbar {
    font-family: "averta-semibold" !important;
	line-height: 62px;
	letter-spacing: -0.1px
}
`

const NavigationItems = [
    // {
    //     text: "Experience",
    //     url: "/#experience",
    // },
    // {
    //     text: "Projects",
    //     url: "/#projects",
    // },
    // {
    //     text: "About",
    //     url: "/#about",
    // },
    // {
    //     text: "Contact",
    //     url: "/#contact",
    // },
]

const Nav = () => {
    return (
        <div className="hero-head">
            <StyledNavigation className="navbar">
                <div className="container styled-navbar" data-sal="slide-down" data-sal-delay="0" data-sal-duration="600">
                    <div className="navbar-brand">
                        <span className="navbar-burger" data-target="navigation-bar">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>
                    <div id="navigation-bar" className="navbar-menu">
                        <div className="navbar-end">
                            {NavigationItems.map(item => (
                                <a key={item.text} className="navbar-item" href={item.url}>{item.text}</a>
                            ))}
                            {/* <div className="navbar-item">
                                <div className="buttons">
                                    <a className="button is-secondary" href="static/jamesneill.codes.cv.pdf">Curriculum vitae</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </StyledNavigation>
        </div>
    );
};

export default Nav;