import * as React from 'react';
import { Nav } from '@components';
import styled from 'styled-components';

const StyledIntro = styled.section`
.styled-intro-title {
    font-family: "averta-semibold" !important;
	font-size: 42px;
	line-height: 62px;
	letter-spacing: -2.5px
}
.styled-intro-subtitle {
    font-family: "averta-regular" !important;
	letter-spacing: -0.5px
}
.styled-intro-link {
    font-weight: bold;
    border-bottom: 1px dotted;
}
.name {
    font-weight: semibold;
}
`


const Intro = () => {
    return (
        <StyledIntro className="hero is-link is-fullheight">
            <Nav />
            <div className="hero-body">
                <div className="container">
                    <p className="styled-intro-title title" data-sal="slide-up" data-sal-delay="100" data-sal-duration="600">Hello, world! <span role="img" aria-label="waving hand emoji">👋</span></p>
                    <p className="styled-intro-subtitle subtitle" data-sal="slide-up" data-sal-delay="700" data-sal-duration="600">My name is <a className="name styled-intro-link" href="mailto:hello@jamesneill.codes">James Neill</a>, and I'm a Site Reliability Engineer at <a href="https://booking.com/" className="styled-intro-link" title="/ˈbʊkɪŋ/">Booking.com</a> located in Amsterdam, NL.</p>
                </div>
            </div>
        </StyledIntro>
    );
};

export default Intro;
