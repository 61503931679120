import * as React from 'react';

const Projects = () => {
    return (
        <div>

        </div>
    );
};

export default Projects;