import * as React from 'react';
import styled from 'styled-components';


const ExperienceItems = [
    {
        company: "Linguamatics",
        title: "Software Engineer",
        when: "Apr. 2019 - Now",
        image: {
            path: "/static/icons/linguamatics.png",
            alt: "Linguamatics, an IQVIA company",
        },
        tags: [
            'Python',
            'Docker',
            'Kubernetes',
            'Scrum',
            'Git',
            'Mercurial',
        ]
    },
    {
        company: "Oxford Instruments",
        title: "Graduate Software Engineer",
        when: "June 2018 - Apr. 2019",
        image: {
            path: "/static/icons/oxford-instruments.png",
            alt: "Oxford Instruments",
        },
        tags: [
            'C#',
            'XAML',
            'C++',
            'JIRA',
            'Kanban',
            'SVN',
        ]
    },
    {
        company: "mtc. media",
        title: "Web Development Intern",
        when: "June 2017 - Aug. 2017",
        image: {
            path: "/static/icons/mtc-media.png",
            alt: "mtc. media",
        },
        tags: [
            'PHP',
            'Laravel',
            'Git',
        ]
    },
    {
        company: "University of Dundee",
        title: "Lab Tutor",
        when: "Sept. 2016 - May 2017",
        image: {
            path: "/static/icons/university-of-dundee.png",
            alt: "University of Dundee",
        },
        tags: [
            'Algorithms',
            'Artifical Intelligence',
            'Computer Architecture',
            'Operating Systems',
            'Physical Computing',
        ]
    },
]

const StyledExperienceItem = styled.div`
.experience-item {
    padding: 7px;
	flex-grow: 1;
	text-align: start;
}
.experience-item-inner {
    position: relative;
	height: 100%;
	background: #fff;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 25px;
    padding-bottom: 91px;
	border-radius: 28px;
}
.experience-item-inner-active {
    background-color: #F0F1F2;
	cursor: pointer;
}

.experience-item-icon {
	width: 15%;
    float: left;
}
.experience-item-icon img {
    max-height: 60px;
}
.experience-summary {
    float: right;
	width: 80%;
	margin-left: 8px;
}
.experience-tags-list {
    display: inline;
    margin-top: 20px;
}
.experience-tags-list > li {
    display: inline;
    margin-right: 5px;
}
`

const ExperienceItem = (props) => {
    return (
        <div className="experience-item" data-sal="slide-up" data-sal-delay="400" data-sal-duration="600">
            <div className={"experience-item-inner " + (props.active ? "experience-item-inner-active" : "")}>
                <div className="experience-item-icon">
                    <img src={props.image.path} alt={props.image.alt} />
                </div>
                <div className="experience-summary">
                    <h4 className="experience-title">{props.title}</h4>
                    <p className="text-sup">{props.company}</p>
                    <p className="text-sup">{props.when}</p>
                    <ul className="experience-tags-list">
                        {props.tags.map(tag => (
                            <li key={tag}><span className="tag is-rounded">{tag}</span></li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const Experience = () => {
    return (
        <section className="hero is-white" id="experience">
            <div className="hero-head">
                <h2 className="title has-text-centered mt-6 section-title" data-sal="slide-up" data-sal-delay="0" data-sal-duration="600">Experience</h2>
            </div>
            <div className="hero-body">
                <div className="columns is-multiline">
                    {ExperienceItems.map((item, index) => (
                        <StyledExperienceItem className={ index % 2 === 0 ? "column is-4 is-offset-2" : "column is-4"} key={item.company}>
                            <ExperienceItem active={item.current} company={item.company} title={item.title} image={item.image} when={item.when} tags={item.tags}/>
                        </StyledExperienceItem>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Experience;