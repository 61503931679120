import * as React from 'react';

const Contact = () => {
    return (
        <section className="section">
            <div className="container py-4">
                <h2 className="title has-text-centered mb-6">Get in touch</h2>
                <div className="columns">
                    <div className="column is-6">
                        <h4 className="title is-spaced is-4">Blah Blah</h4>
                        <p className="subtitle">taco taco burrito burrito</p>
                        <div>
                            <div className="media">
                                <div className="media-left">
                                    <img src="static/icons/map-marker.svg" alt=""></img>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <p>kkkk.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="media">
                                <div className="media-left">
                                    <img src="static/icons/phone.svg" alt=""></img>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <a href="tel:+441233542069">(012) 335 42069</a>
                                    </div>
                                </div>
                            </div>
                            <div className="media">
                                <div className="media-left">
                                    <img src="static/icons/envelope.svg" alt=""></img>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <a href="mailto:hello@jamesneill.codes">hello@jamesneill.codes</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-5 is-offset-1">
                        <form>
                            <div className="field">
                                <div className="control">
                                    <input className="input" type="text" placeholder="Name" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <input className="input" type="email" placeholder="Email" />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control"><textarea className="textarea" rows="5" placeholder="Write something..."></textarea>
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary is-fullwidth" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;